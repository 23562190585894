.DayPicker__withBorder {
  overflow: hidden;
  box-shadow: 0 0 64px 0 rgba(0, 0, 0, 0.3);
}

.DateRangePickerInput__withBorder {
  border-radius: 4px;
  border: solid 1px #dedede;
  padding: 1px;
}

.DateRangePickerInput_arrow {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #001346;
}

.DateInput {
  width: 112px;
}

.DateInput_input {
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.06px;
  color: #001346;
  padding: 18px 8px 18px 14px;
}

.DateInput_input__focused {
  border-bottom: 2px solid #0062c2;
}

.DateRangePickerInput_calendarIcon {
  margin: 0;
  margin-right: 5px;
}

.DateRangePickerInput_calendarIcon:focus,
.DateRangePickerInput_calendarIcon:active {
  outline: none;
}

.DateRangePicker_picker {
  z-index: 20;
}

.DayPicker_wrapper__horizontal {
  margin: 60px 0;
}

.CalendarDay__default,
.CalendarDay__default:hover {
  border: none;
}

.CalendarDay__default:hover {
  background: #e0e2e5;
}

.CalendarDay__highlighted_calendar,
.CalendarDay__highlighted_calendar:active,
.CalendarDay__highlighted_calendar:hover {
  background: #00efc8;
}

.CalendarDay__selected_span {
  background: #a9c9e8;
  color: #001346;
}

.CalendarDay__hovered_span,
.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: #a9c9e8;
  color: #001346;
  opacity: 0.7;
}

.CalendarDay__selected_start,
.CalendarDay__selected_end {
  background: #0062c2;
  color: #fff;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__selected_start:hover,
.CalendarDay__selected_end:hover {
  background: #0062c2;
  color: #fff;
  opacity: 0.7;
}

.CalendarDay__blocked_calendar,
.CalendarDay__blocked_calendar:active,
.CalendarDay__blocked_calendar:hover {
  background: #fff;
  color: #798490;
  border: none;
  opacity: 0.5;
}
.CalendarMonth_caption {
  background: #0062c2;
  color: #fff;
  border-radius: 4px;
  padding: 12px;
  text-align: left;
  margin-bottom: 35px;
}

.DayPickerNavigation_button:focus {
  outline: none;
}

.DayPickerNavigation_button__default:focus {
  outline: none;
}

.DayPickerNavigation_button__disabled {
  display: none;
}

.DayPicker_calendarInfo__horizontal {
  background: #fafafa;
  float: right;
  padding-bottom: 20px;
}
